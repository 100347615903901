import React from 'react';

const NotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404 Page Not Found</h1>
      <p style={styles.text}>Sorry, the page you are looking for doesn't exist.</p>
      <a href="/" style={styles.link}>Go Back to Home</a>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(135deg, #f0f0f0, #d9d9d9)',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    animation: 'fadeIn 1.5s ease',
  },
  title: {
    fontSize: '64px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#ff6b6b',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  text: {
    fontSize: '20px',
    color: '#666',
    marginBottom: '30px',
  },
  link: {
    display: 'inline-block',
    padding: '10px 20px',
    fontSize: '18px',
    color: '#fff',
    backgroundColor: '#3498db',
    textDecoration: 'none',
    borderRadius: '5px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
};

// Add hover effect to the link
styles.link[':hover'] = {
  backgroundColor: '#2980b9',
  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
};

export default NotFound;
